import React from "react";
import PropTypes from "prop-types";
import "../css/global.css";
import styled from "styled-components";

const Wrapper = styled.div``;

const Layout = ({ children }) => {
  return (
    <Wrapper>
      <main>{children}</main>
    </Wrapper>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
